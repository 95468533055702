import styled, { css } from "styled-components"
import { device } from "../../utils/device"

export const StyledHeader = styled.h2`
  color: ${props => props.theme.colors.gray};
  font-family: ${props => props.theme.fontFamily.header};
  font-size: ${props => props.theme.fontSize.l};
  line-height: ${props => props.theme.fontSize.l};
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  z-index: 2;
  text-align: ${props => (props.alignCenter ? "center" : "left")};
  @media ${device.laptop} {
    font-size: ${props => props.theme.fontSize.xxl};
    line-height: ${props => props.theme.fontSize.xxl};
  }
  ${({ lightColor }) =>
    lightColor &&
    css`
      color: ${props => props.theme.colors.lightcolor};
      -webkit-text-stroke: 2px black;
    `}
`

export const StyledHeaderHuge = styled.h1`
  font-size: ${props => props.theme.fontSize.xl};
  line-height: ${props => props.theme.fontSize.xl};
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fontFamily.headerHuge};
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  @media ${device.laptop} {
    font-size: ${props => props.theme.fontSize.xxxl};
    line-height: ${props => props.theme.fontSize.xxxl};
  }
`
