import { createGlobalStyle } from "styled-components"

export const theme = {
  colors: {
    primary: "#D8E8F9",
    secondary: "#859CE5",
    accent: "#DB5461",
    white: "#FFFFFF",
    lightcolor: "#F2B880",
    gray: "#555353",
    black: "#2F243A",
  },
  fontSize: {
    xs: "18px",
    s: "20px",
    m: "24px",
    l: "30px",
    xl: "43px",
    xxl: "65px",
    xxxl: "100px",
  },
  fontFamily: {
    headerHuge: "Felt Tip",
    header: "Blakely",
    subheader: "Felt Tip",
    buttons: "Felt Tip",
    paragraph: "PT Serif, Helvatica, Arial, sans-serif",
    postTile: "Felt Tip",
    postDescription: "PT Serif, Helvatica, Arial, sans-serif",
    hugeFont: "Blakely",
    fixedContact: "Skia",
  },
  shadows: {
    postShadow: "0px 0px 25px #2F243A",
    whiteShadow: "15px 15px 30px #2F243A, -15px -15px 30px #ffffff;",
    cardShadow: "15px 15px 30px #2F243A, -15px -15px 30px #ffffff;",
    cardHoverShadow: "15px 15px 30px #333333, -15px -15px 30px #ffffff;",
    buttonShadow: "0px 0px 10px #2F243A",
    buttonHoverShadow: "0px 0px 20px #c2c2c2",
  },
}

export const GlobalStyle = createGlobalStyle`
 body, h1,h2,h3,h4,h5,h6, p, figure, section, article{
        margin:0;
        padding:0;
    }
    *, *::before, *::after{
        box-sizing: border-box;
     
    }
    *:focus {
        outline: none;

}
      body{
        width:100vw;
        width: 100%;
        min-height: 100vh;
        margin: 0 auto;
        overflowX: hidden;
}
.page-wrapper{
  overflow-x: hidden;
}
   
p{
    font-style: normal;
}
a {
        display: inline-block;
        text-decoration: none;
        font-style: normal;
        color: ${theme.colors.black}
    }
    .aboutMe__container--paragraph{
        max-width: 600px;
    }
    .accent-color{
        color: ${theme.colors.accent}
    }
`
