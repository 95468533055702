import styled, { css } from "styled-components"
import { device } from "../../utils/device"

export const StyledSubheader = styled.h3`
  font-family:  ${props => props.theme.fontFamily.subheader};
  font-size:  ${props => props.theme.fontSize.l};
  line-height: ${props => props.theme.fontSize.l};
  font-weight: 400;
  color: ${props => props.theme.colors.black}
  letter-spacing: 100;
  @media ${device.laptop}{
    font-size:  ${props => props.theme.fontSize.xl};
  }
  ${({ subheaderSmall }) =>
    subheaderSmall &&
    css`
      font-size: ${props => props.theme.fontSize.m};
      line-height: ${props => props.theme.fontSize.m};
      @media ${device.laptop} {
        font-size: ${props => props.theme.fontSize.l};
        line-height: ${props => props.theme.fontSize.l};
      }
    `}
`
